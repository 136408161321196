import Papa, { ParseResult } from 'papaparse';
import chardet from 'chardet';
import iconv from 'iconv-lite';

// Define the column mapping (Japanese to English)
const COLUMN_MAPPING: Record<string, keyof csvItem> = {
  ファイルの固有ID: 'sdsID',
  PDFのファイル名: 'pdfName',
  リスクアセスメント実施日: 'riskAssessDate',
  サプライヤーへの確認日: 'supplierConfirmationDate',
  担当部署: 'department',
  タグ: 'tag',
  製品コード: 'productCode',
} as const;

// Define the csvItem type (ensure this matches your actual type)
export interface csvItem {
  sdsID?: string;
  pdfName?: string;
  riskAssessDate?: string;
  supplierConfirmationDate?: string;
  department?: string;
  tag?: string;
  productCode?: string;
}

// Helper function to compare two sets
export const areSetsEqual = (set1: Set<string>, set2: Set<unknown>) => {
  if (set1.size !== set2.size) return false;
  for (const item of set1) {
    if (!set2.has(item)) return false;
  }
  return true;
};

export const filterDuplicateTextInArray = (
  stringArray: string[]
): { seen: string[]; duplicates: string[] } => {
  const seen = new Set<string>();
  const duplicates = new Set<string>();

  stringArray.forEach((item) => {
    if (seen.has(item)) {
      duplicates.add(item);
    } else {
      seen.add(item);
    }
  });

  return {
    seen: Array.from(seen),
    duplicates: Array.from(duplicates),
  };
};

export const fixStr = (str: string): string => {
  return (
    str
      // .replace(/[\u003F\uFFFD]{2,}/g, '\u3231') // ?? or �� → ㈱ (multiple ?/�)
      // .replace(/[\u003F\uFFFD]/g, '\u3231') // Default: ? or � → ㈱
      .replace(/[\u2212\uFF0D]/g, '\u002D') // − or － → -
      .replace(/[\uFF1B\u003B]/g, '\uFF1B') // ; (U+003B) → ； (U+FF1B, full-width)
      .replace(/[\u301C\uFF5E]/g, '\uFF5E')
      .replace(/\s+/g, ' ') // Collapse multiple spaces
      .replace(/ _/g, '_') // Remove space before underscore
      .normalize('NFC') // Unicode normalization
      .trim()
  );
};

export const parseCSV = (file: File): Promise<csvItem[]> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      try {
        // Read the file as an ArrayBuffer
        const csvResult = e?.target?.result as ArrayBuffer;
        if (!csvResult) {
          throw new Error('Failed to read file content');
        }

        // Detect encoding
        const uint8Array = new Uint8Array(csvResult);
        let detectedEncoding = chardet.detect(uint8Array) || 'UTF-8';
        if (detectedEncoding === 'ISO-8859-1') {
          detectedEncoding = 'UTF-8';
        }

        // Decode the data
        let decodedData = iconv.decode(
          Buffer.from(csvResult),
          detectedEncoding
        );
        if (decodedData.charCodeAt(0) === 0xfeff) {
          decodedData = decodedData.slice(1); // Remove BOM
        }

        // Parse the CSV
        Papa.parse(decodedData, {
          header: true,
          skipEmptyLines: true,
          complete: (results: ParseResult<Record<string, string>>) => {
            try {
              // Validate headers
              const expectedHeaders = Object.keys(COLUMN_MAPPING);
              const actualHeaders = results.meta.fields || [];
              const missingHeaders = expectedHeaders.filter(
                (header) => !actualHeaders.includes(header)
              );
              if (missingHeaders.length > 0) {
                throw new Error(
                  `Missing required headers: ${missingHeaders.join(', ')}`
                );
              }

              // Map and normalize the data
              const normalizedData = results.data.map((row) => {
                const normalizedRow: csvItem = {};
                for (const [jpKey, enKey] of Object.entries(COLUMN_MAPPING)) {
                  const value = row[jpKey];
                  normalizedRow[enKey] =
                    typeof value === 'string' ? value.normalize('NFC') : '';
                }
                return normalizedRow;
              });

              resolve(normalizedData);
            } catch (error) {
              reject(error instanceof Error ? error : new Error(String(error)));
            }
          },
          error: (error: Error) => {
            reject(error);
          },
        });
      } catch (error) {
        reject(error instanceof Error ? error : new Error(String(error)));
      }
    };

    reader.onerror = () => {
      reject(new Error('Failed to read file'));
    };

    reader.readAsArrayBuffer(file);
  });
};

export const updateChangeDateType = (dateString: string | null | undefined) => {
  if (dateString) {
    const date = new Date(dateString);
    const changedDateString =
      date.getFullYear() +
      '-' +
      String(date.getMonth() + 1).padStart(2, '0') +
      '-' +
      String(date.getDate()).padStart(2, '0');

    return changedDateString;
  } else return null;
};

export const updateCSVFileDateFormatType = (csvDatas: csvItem[]) => {
  return csvDatas.map((data) => {
    let {
      riskAssessDate: risk_assess_date,
      supplierConfirmationDate: supplier_confirmation_date,
    } = data;

    // Format リスクアセスメント実施日 if it exists and is not already in YYYY-MM-DD format
    if (risk_assess_date && !/^\d{4}-\d{2}-\d{2}$/.test(risk_assess_date)) {
      const date = new Date(risk_assess_date);
      risk_assess_date =
        date.getFullYear() +
        '-' +
        String(date.getMonth() + 1).padStart(2, '0') +
        '-' +
        String(date.getDate()).padStart(2, '0');
    }

    // Format サプライヤーへの確認日 if it exists and is not already in YYYY-MM-DD format
    if (
      supplier_confirmation_date &&
      !/^\d{4}-\d{2}-\d{2}$/.test(supplier_confirmation_date)
    ) {
      const date = new Date(supplier_confirmation_date);
      supplier_confirmation_date =
        date.getFullYear() +
        '-' +
        String(date.getMonth() + 1).padStart(2, '0') +
        '-' +
        String(date.getDate()).padStart(2, '0');
    }

    // Return the updated data object
    return {
      ...data,
      riskAssessDate: risk_assess_date,
      supplierConfirmationDate: supplier_confirmation_date,
    };
  });
};
